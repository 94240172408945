body {
  margin: 0;
  font-family: baskerville;
  background-color: rgb(231, 231, 231);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
